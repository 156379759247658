import { render, staticRenderFns } from "./CpttEdit.vue?vue&type=template&id=06bc5f10&scoped=true&"
import script from "./CpttEdit.vue?vue&type=script&lang=js&"
export * from "./CpttEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06bc5f10",
  null
  
)

export default component.exports