<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 ref="childTab1" />
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">
        <childTab2 @draftState="draftState" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab3 />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CpttEditMain from '@/views/SystemManagement/CpttManage/TabChild/CpttEditMain';
import CpttEditAbout from '@/views/SystemManagement/CpttManage/TabChild/CpttEditAbout';
import CpttEditAppr from '@/views/SystemManagement/CpttManage/TabChild/CpttEditAppr';
export default {
  name: 'CpttEdit',
  components: {
    childTab1: CpttEditMain,
    childTab2: CpttEditAppr,
    childTab3: CpttEditAbout
  },
  data() {
    return {
      activeName: 'first'
    };
  },
  created() {},
  methods: {
    handleClick() {
      if (this.activeName === 'first') {
        this.$refs.childTab1.initData();
      }
    },
    draftState() {
      this.$refs.childTab1.initData();
    }
  }
};
</script>

<style scoped lang="scss"></style>
