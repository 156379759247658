<template>
  <div>
    <appr-main :isShow="true" @draftState="draftState"></appr-main>
  </div>
</template>

<script>
import apprMain from '@/views/component/apprCommon/apprMain';
export default {
  name: 'CpttEditAppr',
  components: {
    apprMain
  },
  methods: {
    draftState() {
      this.$emit('draftState');
    }
  }
};
</script>

<style scoped></style>
